import React, {Component} from 'react';
import ElenaGanchur from './elena-ganchur.jpg';
import BitfactoryLogo from './bitfactory-logo-slim.svg';
import './App.css';
import {
	Box,
	CssBaseline,
	Grid,
	Typography,
	createTheme,
	Button,
	Container,
	TextField, CircularProgress, Theme, Link, Snackbar, Divider
} from "@material-ui/core";
import {ThemeProvider} from '@material-ui/core/styles';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import 'firebase/firestore';
import moment from 'moment';
import Image from 'material-ui-image'
import Alert from '@material-ui/lab/Alert';
import LoadingButton from '@material-ui/lab/LoadingButton';
import SendIcon from '@material-ui/icons/Send';

const theme = createTheme({
	typography: {
		fontFamily: [
			'IBM Plex Sans',
			'sans-serif',
		].join(','),
		h4: {
			fontWeight: 600,
		},
		h5: {
			fontWeight: 600,
		},
	},
	palette: {
		primary: {
			main: '#0055ff',
		},
		background: {
			default: "#ffffff"
		},
		text: {
			primary: "#001133"
		}
	},
	components: {
		MuiFilledInput: {
			styleOverrides: {
				root: {
					backgroundColor: '#f3f3f3',
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
					"&&&:before": {
						borderBottom: "none"
					},
					"&&:after": {
						borderBottom: "none"
					}
				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					color: '#001133',
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 0,
					paddingLeft: "2em",
					paddingRight: "2em",
				}
			}
		},
		MuiLink: {
			styleOverrides: {
				root: {
					fontWeight: 600,
					fontSize: "16px",
				}
			}
		}
	},
});

const firebaseConfig = {
	apiKey: "AIzaSyAiR3OznNZERZim7HzybFBryZrqwgA6sgQ",
	authDomain: "bitfactory-support.firebaseapp.com",
	databaseURL: "https://bitfactory-support.firebaseio.com",
	projectId: "bitfactory-support",
	storageBucket: "bitfactory-support.appspot.com",
	messagingSenderId: "599104301180",
	appId: "1:599104301180:web:ceeeb15b43892fa4"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const styles = (theme: Theme) => ({
	root: {
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
		border: 0,
		borderRadius: 3,
		boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
		color: 'white',
		height: 48,
		padding: '0 30px',
	},
});

interface AppProps {
	key?: string
}

interface AppState {
	email: string;
	key: string;
	summary: string;
	affectedVersion: string;
	osVersion: string;
	browser?: string;
	date: string;
	steps?: string;
	actualResult?: string;
	expectedResult?: string;
	file?: File,
	loading: boolean;
	openSuccess: boolean;
	openError: boolean;
	ticketKey: string;
}

class App extends Component<AppProps, AppState> {

	constructor(props: any) {
		super(props);

		let currentKey = new URLSearchParams(window.location.search).get("key");

		if (!currentKey) {
			currentKey = "";
		}

		this.state = {
			email: '',
			key: currentKey,
			summary: '',
			affectedVersion: '',
			osVersion: '',
			date: moment(Date()).format("YYYY-MM-DD"),
			loading: false,
			openSuccess: false,
			openError: false,
			ticketKey: '-'
		};
	}

	handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

		if (event.target.name === "email") {
			this.setState({email: event.target.value});
		} else if (event.target.name === "key") {
			this.setState({key: event.target.value});
		} else if (event.target.name === "summary") {
			this.setState({summary: event.target.value});
		} else if (event.target.name === "affectedVersion") {
			this.setState({affectedVersion: event.target.value});
		} else if (event.target.name === "osVersion") {
			this.setState({osVersion: event.target.value});
		} else if (event.target.name === "browser") {
			this.setState({browser: event.target.value});
		} else if (event.target.name === "date") {
			this.setState({date: event.target.value});
		} else if (event.target.name === "steps") {
			this.setState({steps: event.target.value});
		} else if (event.target.name === "actualResult") {
			this.setState({actualResult: event.target.value});
		} else if (event.target.name === "expectedResult") {
			this.setState({expectedResult: event.target.value});
		} else if (event.target.name === "file") {
			let files = event.target.files;
			if (files) {
				this.setState({file: files[0]});
			}
		}
	};

	handleSubmit = async (event: any) => {
		event.preventDefault();

		this.setState({loading: true});

		const formData = new FormData();
		formData.append('email', this.state.email);
		formData.append('key', this.state.key);
		formData.append('summary', this.state.summary);
		formData.append('affectedVersion', this.state.affectedVersion);
		formData.append('osVersion', this.state.osVersion);
		formData.append('date', this.state.date);

		if (this.state.browser) {
			formData.append('browser', this.state.browser);
		}

		if (this.state.steps) {
			formData.append('steps', this.state.steps);
		}

		if (this.state.actualResult) {
			formData.append('actualResult', this.state.actualResult);
		}

		if (this.state.expectedResult) {
			formData.append('expectedResult', this.state.expectedResult);
		}

		if (this.state.file) {
			formData.append('file', this.state.file);
		}

		let options: RequestInit = {
			method: 'POST',
			body: formData
		};

		try {
			let response = await fetch('https://europe-west3-bitfactory-support.cloudfunctions.net/uploadFile', options);
			let data = await response.text();

			if (data === "") {
				this.setState({openError: true});
			} else {
				this.setState({ticketKey: data});
				this.setState({openSuccess: true});
			}
		} catch (error) {
			this.setState({openError: true});
		}

		this.setState({loading: false});
	};

	handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		this.setState({openSuccess: false});
		this.setState({openError: false});
		this.setState({ticketKey: '-'});
	};

	render() {
		return (
			<ThemeProvider theme={theme}>
				<CssBaseline/>
				<Container maxWidth="md">
					<Box mt={6} mb={6}>
						<Grid container>
							<Grid xs={12} sm={7} item>
								<Box mb={3}>
									<Typography variant="h4" gutterBottom>
										Welcome to the <Box color='primary.main'>Bitfactory
										Support</Box>
									</Typography>
									<Typography variant="body1" gutterBottom>
										Please fill out the following form in order to better understand the problem you
										have. If you are not sure how to go through the form, please contact Elena and
										she will help you out.
									</Typography>
								</Box>
							</Grid>
							<Grid sm={2} item></Grid>
							<Grid xs={12} sm={3} item>
								<Grid container spacing={3}>
									<Grid item xs={6} sm={12}>
										<Box mb={2}>
											<Image src={ElenaGanchur}/>
										</Box>
									</Grid>
									<Grid item xs={6} sm={12}>
										<Typography variant="h5">
											Elena Ganchur
										</Typography>
										<Typography>
											Project Management
										</Typography>
										<Box mt={2} mb={2}>
											<Link href={"tel:+4971121723738"}>
												+49 711 21723738
											</Link>
											<Typography>
												MO-FR: 9 - 18 UHR
											</Typography>
										</Box>
										<Box mb={10}>
											<Link href={"mailto:support@bitfactory.io"}>
												support@bitfactory.io
											</Link>
										</Box>
									</Grid>
								</Grid>
							</Grid>
							<Grid sm={12} item>
								<form autoComplete="off" onSubmit={this.handleSubmit}>

									<Grid container spacing={3}>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Your Email Address"
													   variant="filled" type="email"
													   name="email"
													   value={this.state.email} onChange={this.handleChange} required/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Project ID" variant="filled"
													   name="key"
													   value={this.state.key} onChange={this.handleChange} required
													   helperText="If you don't know your Project ID please contact us: support@bitfactory.io"/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<TextField fullWidth id="filled-basic" label="Problem Title"
													   variant="filled"
													   name="summary"
													   value={this.state.summary} onChange={this.handleChange} required
													   helperText="Describe the problem in a few words"/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Date Raised" variant="filled"
													   name="date" inputProps={{ max: moment(Date()).format("YYYY-MM-DD") }}
													   value={this.state.date} onChange={this.handleChange} required
													   type="date" helperText="Date when the bug is raised"/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Affected Software Version"
													   variant="filled"
													   name="affectedVersion"
													   value={this.state.affectedVersion} onChange={this.handleChange}
													   required
													   helperText="Which version of the application are you testing?"/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Operating System"
													   variant="filled"
													   name="osVersion"
													   value={this.state.osVersion} onChange={this.handleChange}
													   required
													   helperText="What operating system and version are you using?"/>
										</Grid>
										<Grid item sm={6} xs={12}>
											<TextField fullWidth id="filled-basic" label="Browser Version"
													   variant="filled"
													   name="browser"
													   value={this.state.browser} onChange={this.handleChange}
													   helperText="What browser and version are you using?"/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<TextField fullWidth id="filled-basic" label="Steps to Reproduce"
													   variant="filled"
													   name="steps"
													   value={this.state.steps} onChange={this.handleChange}
													   multiline
													   rows={4}
													   helperText="What steps did you take before the error occurred?"/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<TextField fullWidth id="filled-basic" label="Actual Result"
													   variant="filled"
													   name="actualResult"
													   value={this.state.actualResult} onChange={this.handleChange}
													   multiline
													   rows={4}
													   helperText="What happens in the application after the above steps have been executed?"/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<TextField fullWidth id="filled-basic" label="Expected Result"
													   variant="filled"
													   name="expectedResult"
													   value={this.state.expectedResult} onChange={this.handleChange}
													   multiline
													   rows={4}
													   helperText="What should the application have done correctly?"/>
										</Grid>
										<Grid item sm={12} xs={12}>
											<input
												accept="image/*"
												style={{ display: 'none' }}
												id="raised-button-file"
												onChange={this.handleChange}
												type="file"
												name="file"
											/>
											<label htmlFor="raised-button-file">
												<Button variant="contained" component="span">
													Upload Screenshot
												</Button>

												<Box component="span" ml={2}><Typography display="inline">
													{this.state.file?.name}
												</Typography>
												</Box>
											</label>
										</Grid>

										<Grid item sm={12} xs={12}>
											<LoadingButton
												type="submit"
												endIcon={<SendIcon />}
												loading={this.state.loading}
												loadingPosition="end"
												variant="contained"
											>
												Submit
											</LoadingButton>

											<Snackbar open={this.state.openSuccess} autoHideDuration={6000} onClose={this.handleClose}>
												<Alert onClose={this.handleClose} severity="success">
													Thanks for your support message! We have sent a confirmation email which also includes the following ticket id to your case: {this.state.ticketKey}
												</Alert>
											</Snackbar>
											<Snackbar open={this.state.openError} autoHideDuration={6000} onClose={this.handleClose}>
												<Alert onClose={this.handleClose} severity="error">
													Something went wrong! Please check your input
												</Alert>
											</Snackbar>
										</Grid>
									</Grid>
								</form>
							</Grid>
							<Grid xs={12} item>
								<Box mt={4}>
									<Divider light variant="fullWidth" />
								</Box>
								<Box mt={4} display="flex" flexDirection="row">
									<Box width={35} mr={4}>
										<Image imageStyle={{width: 35, height: 30}} src={BitfactoryLogo}/>
									</Box>
									<Box ml="auto" mr={2}>
										<Link href="https://www.bitfactory.io/de/impressum/">
											Imprint
										</Link>
									</Box>
									<Box>
										<Link href="https://www.bitfactory.io/de/datenschutz/">
											Data Privacy
										</Link>
									</Box>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Container>

			</ThemeProvider>
		)
	};
}

export default App;
